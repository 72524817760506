<template>
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox">
        <div title="班级编码" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">班级编码:</span>
          <el-input v-model="projectCode" type="text" size="small" placeholder="请输入班级编号"
            onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" clearable />
        </div>
        <div title="班级名称" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">班级名称:</span>
          <el-input v-model="projectName" type="text" size="small" placeholder="请输入班级名称" clearable />
        </div>
        <div title="行政区划" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">行政区划:</span>
          <el-cascader v-model="areaId" :options="areatreeList" :props="propsarea" :disabled="disabled" clearable
            filterable size="small"></el-cascader>
        </div>
      </div>
      <div class="searchbox" style="margin-top: 10px">
        <div title="机构名称" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">机构名称:</span>
          <el-select size="small" v-model="compId" remote :remote-method="getCompanyList" filterable clearable
            placeholder="请至少输入两个字搜索">
            <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
              :value="item.compId"></el-option>
          </el-select>
        </div>
        <span title="培训类型" class="searchboxItem ci-full flexcc">
          <span class="itemLabel" style="min-width: 6rem">培训类型:</span>
          <tree ref="tree" @eventBtn="childBack" :ruleForm="ruleForm" size="small" @clearParams="clearParams" modal
            typeStu />
        </span>
        <div title="补贴班级" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">补贴班级:</span>
          <el-select v-model="isSubsidy" placeholder="请选择是否补贴班级" size="small" clearable>
            <el-option v-for="item in SubsidyList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
      <div class="searchbox" style="margin-top: 10px">
        <div title="负责人姓名" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">负责人姓名:</span>
          <el-input v-model="projectUser" type="text" size="small" placeholder="请输入负责人姓名" clearable />
        </div>
        <div title="负责人电话" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width: 6rem">负责人电话:</span>
          <el-input v-model="projectUserPhone" type="text" size="small" placeholder="请输入负责人电话" clearable />
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          style="width: 100%" :header-cell-style="tableHeader" stripe @sort-change="changeSort"
          :default-sort="{ prop: 'auditTime', order: 'ascending' }">
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed width="100px" />
          <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" width="200" fixed />
          <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="200" />
          <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaNamePath" width="150" />
          <el-table-column label="培训类型" align="left" show-overflow-tooltip prop="trainTypeNamePath" width="120" />
          <el-table-column label="机构名称" align="left" show-overflow-tooltip prop="compName" width="200" />
          <el-table-column label="岗位类型" align="left" show-overflow-tooltip width="120">
            <template slot-scope="scope">{{
              scope.row.postName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="行业类型" align="left" show-overflow-tooltip width="120">
            <template slot-scope="scope">{{
              scope.row.industryNamePath || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="职业/工种" align="left" show-overflow-tooltip width="120">
            <template slot-scope="scope">{{
              scope.row.occupationNamePath || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="培训等级" align="left" show-overflow-tooltip width="120">
            <template slot-scope="scope">{{
              scope.row.trainLevelName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="负责人姓名" align="left" show-overflow-tooltip prop="projectUser" />
          <el-table-column label="负责人电话" width="120" align="left" show-overflow-tooltip prop="projectUserPhone" />
          <el-table-column label="是否开始学习" align="center" show-overflow-tooltip width="130" prop="startStudy"
            v-if="name == 'second'">
            <template slot-scope="scope">
              <span>{{ scope.row.startStudy == false ? "否" : "有" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否补贴班级" align="center" show-overflow-tooltip width="130" prop="isSubsidy">
            <template slot-scope="scope">
              <span>{{ scope.row.isSubsidy ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="提交审核时间" align="left" show-overflow-tooltip prop="auditTime" width="140"
            :sort-orders="['ascending', 'descending']" sortable="custom">
            <template slot-scope="scope">{{
              scope.row.auditTime | moment
            }}</template>
          </el-table-column>
          <el-table-column label="审核人" align="left" show-overflow-tooltip prop="auditName"
            v-if="name == 'second' || name == 'three'" />
          <el-table-column label="审核时间" align="left" show-overflow-tooltip width="200" prop="auditTime"
            v-if="name == 'second' || name == 'three'" sortable="custom" :sort-orders="['ascending', 'descending']">
            <template slot-scope="scope">
              <span>{{ scope.row.auditTime | moment }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" align="left" show-overflow-tooltip minWidth="150" prop="remark">
            <template slot-scope="scope">{{
              scope.row.remark || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="操作" width="130px" show-overflow-tooltip align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" size="mini" style="padding: 0 5px" @click="
              doReview(
                scope.row.projectId,
                scope.row.auditState,
                scope.row.startStudy,
                scope.row.auditState
              )
                ">详情</el-button>
              <el-button type="text" size="mini" style="padding: 0 5px" v-show="name !== 'second'" @click="
                projectPass(
                  scope.row.projectId,
                  scope.row.projectSource,
                  scope.row.auditState,
                  scope.row.trainTypeId,
                  scope.row.belongHebei,
                  name
                )
                ">通过</el-button>
              <el-button type="text" size="mini" style="padding: 0 5px" v-show="name !== 'three'" :disabled="scope.row.startStudy != false ||
                scope.row.projectSource == '20' ||
                (scope.row.projectSource == '40' &&
                  scope.row.auditState == '30')
                " @click="reject(scope.row.projectId, scope.row.auditState, name)">驳回</el-button>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog title="审核事由" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div>
        <el-input type="textarea" placeholder="请输入审核理由" maxlength="100" show-word-limit v-model="auditReason"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialoglevelVisible" width="30%" :before-close="handleClose" center>
      <div style="display: flex; align-items: center">
        <span style="font-size: 1rem; padding-right: 0.25rem">级别:</span>
        <el-select v-model="level" placeholder="请选择" size="small" style="flex: 1">
          <el-option v-for="item in levelList" :key="item.subsidyLevel" :label="item.subsidyLevel"
            :value="item.subsidyLevel"></el-option>
        </el-select>
      </div>
      <p style="padding: 0.5rem 1rem">请仔细核对级别是否正确。</p>
      <p style="padding: 0rem 1rem">
        如果没有您想要的级别，请去补贴标准里增加。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doCancle" class="bgc-bv">取 消</el-button>
        <el-button @click="doSure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
export default {
  name: "CourseToBeReviewed",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      CompanyList: [],
      compId: "",
      names: "",
      projectName: "",
      projectCode: "",
      projectUser: "",
      projectUserPhone: "",
      areaId: "",
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      dialogVisible: false,
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      resourceId: "",
      auditReason: "",
      currentAuditState: "",
      auditTimeStort: "",
      dialoglevelVisible: false,
      level: "",
      levelList: [],
      trainTypeId: "", // 通过按钮时用
      SubsidyList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      isSubsidy: "",
    };
  },
  computed: {},
  created() {
    sessionStorage.setItem("radio", "");
    this.init();
    // this.getData()
  },
  methods: {
    init() {
      this.getTableHeight();
      this.getareatree();
      this.ruleForm.Trainingtype = "";
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getlevel(resourceId) {
      this.$post("/biz/new/subsidy/standard/projectSubsidyList", {
        projectId: resourceId,
      }).then((ret) => {
        this.levelList = ret.data;
      });
    },
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    changeSort(val) {
      this.auditTimeStort = val.order;
      this.getData();
    },
    getData(pageNum, url, name) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        isSubsidy: this.isSubsidy,
      };
      if (this.auditTimeStort) {
        params.auditTimeStort = this.auditTimeStort;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.projectUser) {
        params.projectUser = this.projectUser;
      }
      if (this.projectUserPhone) {
        params.projectUserPhone = this.projectUserPhone;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.compId) {
        params.compId = this.compId;
      }

      if (!name) {
        let routerName = this.$route.query.active;
        if (routerName == "second") {
          url = "/run/project/auditPassListPage";
        } else if (routerName == "three") {
          url = "/run/project/auditRejectListPage";
        } else {
          url = "/run/project/waitAuditListPage";
        }
        this.name = this.$route.query.active || "first";
      }

      this.doFetch({
        url: url,
        params,
        pageNum,
      });
    },
    doReview(projectId, currentAuditState, startStudy, auditState) {
      this.$router.push({
        path: "/web/ClassReviewDetails",
        query: {
          getClassInfo: JSON.stringify({
            projectId,
            startStudy,
            status: this.name,
            currentAuditState,
            auditState,
          }),
        },
      });
    },
    async projectPass(
      resourceId,
      projectSource,
      currentAuditState,
      trainTypeId,
      belongHebei,
      name
    ) {
      // 默认上来点击就给个提示
      const oneTs = await this.$confirm("提示", {
        title: "提示",
        message: "班级价格没有设置,是否确认审核通过",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        closeOnPressEscape: false,
        distinguishCancelAndClose: true,
        confirmButtonClass: "confirmButtonClass",
      })
        .then(() => { return true; })
        .catch((action) => {
          return false;
        })
      if (!oneTs) return;
      this.name = name;
      this.resourceId = resourceId;
      this.trainTypeId = trainTypeId;
      this.projectSource = projectSource;
      this.currentAuditState = currentAuditState;
      const VALIDCONTRACT = await this.$post("/biz/new/bill/contract/project/exist/validContract", { projectId: resourceId })
      if (VALIDCONTRACT.data) {
        const CONFIRM = await this.$confirm("提示", {
          title: "提示",
          message: VALIDCONTRACT.data,
          showCancelButton: true,
          confirmButtonText: "取消",
          cancelButtonText: "确定",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          distinguishCancelAndClose: true,
          confirmButtonClass: "confirmButtonClass",
        })
          .then(() => { return false; })
          .catch((action) => {
            console.log(action);
            if (action == "cancel") {
              return true;
            }
          })
        if (!CONFIRM) return
      }
      this.$post("/biz/reviewRemind/queryReviewRemind", {
        projectId: resourceId,
        remindMode: "20",
      }).then((ret) => {
        if (ret.data.length != 0) {
          let newDatas = [];
          const h = this.$createElement;
          for (const i in ret.data) {
            newDatas.push(
              h("p", null, Number(i) + 1 + "." + ret.data[i].remindText)
            );
          }
          this.$confirm("提示", {
            title: "提示",
            message: h("div", null, newDatas),
            showCancelButton: true,
            confirmButtonText: "取消",
            cancelButtonText: "确定",
            closeOnClickModal: false,
            closeOnPressEscape: false,
            distinguishCancelAndClose: true,
            confirmButtonClass: "confirmButtonClass",
          })
            .then(() => { })
            .catch((action) => {
              if (action == "cancel") {
                this.$post("/biz/project/query", { projectId: resourceId })
                  .then((res) => {
                    if (res.data.originalParameter.areaId == -1000) {
                      this.$confirm(
                        "该地区没有配置课程参数，将采用默认参数",
                        "提示",
                        {
                          showCancelButton: true,
                          confirmButtonText: "取消",
                          cancelButtonText: "确定",
                          closeOnClickModal: false,
                          closeOnPressEscape: false,
                          distinguishCancelAndClose: true,
                          confirmButtonClass: "confirmButtonClass",
                        }
                      )
                        .then(() => { })
                        .catch((action1) => {
                          if (action1 == "cancel") {
                            this.$post(
                              "/run/project/passFrontAdvanceStandard",
                              {
                                projectId: resourceId,
                              }
                            ).then((res) => {
                              if (res.status == "0") {
                                if (res.message == "操作成功！") {
                                  this.doPass(
                                    projectSource,
                                    resourceId,
                                    currentAuditState,
                                    name
                                  );
                                } else {
                                  this.$confirm(res.message, "审核申请", {
                                    confirmButtonText: "取消",
                                    cancelButtonText: "确定",
                                    confirmButtonClass: "confirmButtonClass",
                                  })
                                    .then(() => { })
                                    .catch(() => {
                                      this.doPass(
                                        projectSource,
                                        resourceId,
                                        currentAuditState,
                                        name
                                      );
                                    });
                                }
                              }
                            });
                          }
                        });
                    } else {
                      this.$post("/run/project/passFrontAdvanceStandard", {
                        projectId: resourceId,
                      }).then((res) => {
                        if (res.status == "0") {
                          if (res.message == "操作成功！") {
                            this.doPass(
                              projectSource,
                              resourceId,
                              currentAuditState,
                              name
                            );
                          } else {
                            this.$confirm(res.message, "审核申请", {
                              confirmButtonText: "取消",
                              cancelButtonText: "确定",
                              confirmButtonClass: "confirmButtonClass",
                            })
                              .then(() => {
                                return;
                              })
                              .catch(() => {
                                this.doPass(
                                  projectSource,
                                  resourceId,
                                  currentAuditState,
                                  name
                                );
                              });
                          }
                        }
                      });
                    }
                  })
                  .catch((err) => {
                    return;
                  });
              }
            });
        } else {
          this.$post("/biz/project/query", { projectId: resourceId })
            .then((res) => {
              if (res.data.originalParameter.areaId == -1000) {
                this.$confirm(
                  "该地区没有配置课程参数，将采用默认参数",
                  "提示",
                  {
                    showCancelButton: true,
                    confirmButtonText: "取消",
                    cancelButtonText: "确定",
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    distinguishCancelAndClose: true,
                    confirmButtonClass: "confirmButtonClass",
                  }
                )
                  .then(() => { })
                  .catch((action1) => {
                    if (action1 == "cancel") {
                      this.$post("/run/project/passFrontAdvanceStandard", {
                        projectId: resourceId,
                      }).then((res) => {
                        if (res.status == "0") {
                          if (res.message == "操作成功！") {
                            this.doPass(
                              projectSource,
                              resourceId,
                              currentAuditState,
                              name
                            );
                          } else {
                            this.$confirm(res.message, "审核申请", {
                              confirmButtonText: "取消",
                              cancelButtonText: "确定",
                              confirmButtonClass: "confirmButtonClass",
                            })
                              .then(() => {
                                return;
                              })
                              .catch(() => {
                                this.doPass(
                                  projectSource,
                                  resourceId,
                                  currentAuditState,
                                  name
                                );
                              });
                          }
                        }
                      });
                    }
                  });
              } else {
                this.$post("/run/project/passFrontAdvanceStandard", {
                  projectId: resourceId,
                }).then((res) => {
                  if (res.status == "0") {
                    if (res.message == "操作成功！") {
                      this.doPass(
                        projectSource,
                        resourceId,
                        currentAuditState,
                        name
                      );
                    } else {
                      this.$confirm(res.message, "审核申请", {
                        confirmButtonText: "取消",
                        cancelButtonText: "确定",
                        confirmButtonClass: "confirmButtonClass",
                      })
                        .then(() => {
                          return;
                        })
                        .catch(() => {
                          this.doPass(
                            projectSource,
                            resourceId,
                            currentAuditState,
                            name
                          );
                        });
                    }
                  }
                });
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    doPush(name) {
      if (name == "first") {
        this.getData(-1, "/run/project/waitAuditListPage", name);
      } else if (name == "second") {
        this.getData(-1, "/run/project/auditPassListPage", name);
      } else if (name == "three") {
        this.getData(-1, "/run/project/auditRejectListPage", name);
      }
    },
    doPass(projectSource, resourceId, currentAuditState, name) {
      if (projectSource == "20") {
        this.$confirm(
          "外部班级审核通过之后不可以在驳回,该班级确定审核通过吗？",
          "审核申请",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "confirmButtonClass",
          }
        )
          .then(() => {
            this.$post("/interface/cCSkillsTraing/openClassSure", {
              projectId: resourceId,
            })
              .then((ret) => {
                if (ret.status == 0) {
                  this.$message({
                    message: "审核成功",
                    type: "success",
                  });
                  this.doPush(name);
                }
              })
              .catch((err) => {
                return;
              });
          })
          .catch(() => {
            return;
          });
        //郑州班级的验证临时关闭 -- 40
      } else if (projectSource == "40") {
        this.$post("/biz/heNan/pushProject/pushClassInfoApproval", {
          projectId: resourceId,
        })
          .then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: "审核成功",
                type: "success",
              });
              this.doPush(name);
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        this.$post("/run/project/modifyAuditPassState", {
          resourceId,
          auditState: "30",
          currentAuditState,
        })
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                type: "success",
                message: "审核成功",
              });

              this.doPush(name);
            }
          })
          .catch((err) => {
            return;
          });
      }
    },
    reject(resourceId, currentAuditState, name) {
      this.resourceId = resourceId;
      this.currentAuditState = currentAuditState;
      this.dialogVisible = true;
      this.name = name;
    },
    doSure() {
      const parmar = {
        subsidyLevel: this.level,
        resourceId: this.resourceId,
        auditState: "30",
        currentAuditState: this.currentAuditState,
      };
      // if (this.trainTypeId == "5" || this.trainTypeId == "16") {
      //   if (this.level == "") {
      //     this.$message.error("请选择级别");
      //     return;
      //   }
      // }
      if (this.projectSource == "20") {
        this.$confirm(
          "外部班级审核通过之后不可以在驳回,该班级确定审核通过吗？",
          "审核申请",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "confirmButtonClass",
          }
        )
          .then(() => {
            this.$post("/interface/cCSkillsTraing/openClassSure", {
              projectId: this.resourceId,
            })
              .then((ret) => {
                if (ret.status == 0) {
                  this.$message({
                    message: "审核成功",
                    type: "success",
                  });
                  // this.doCancle();
                  if (this.name == "first") {
                    this.getData(
                      -1,
                      "/run/project/waitAuditListPage",
                      this.name
                    );
                  } else if (this.name == "second") {
                    this.getData(
                      -1,
                      "/run/project/auditPassListPage",
                      this.name
                    );
                  } else if (this.name == "three") {
                    this.getData(
                      -1,
                      "/run/project/auditRejectListPage",
                      this.name
                    );
                  }
                }
              })
              .catch((err) => {
                return;
              });
          })
          .catch(() => {
            return;
          });
        //郑州班级的验证临时关闭 -- 40
      } else if (this.projectSource == "40") {
        this.$post("/biz/heNan/pushProject/pushClassInfoApproval", {
          projectId: this.resourceId,
        })
          .then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: "审核成功",
                type: "success",
              });
              // this.doCancle();
              if (this.name == "first") {
                this.getData(-1, "/run/project/waitAuditListPage", this.name);
              } else if (this.name == "second") {
                this.getData(-1, "/run/project/auditPassListPage", this.name);
              } else if (this.name == "three") {
                this.getData(-1, "/run/project/auditRejectListPage", this.name);
              }
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        this.$post("/run/project/modifyAuditPassState", parmar)
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                type: "success",
                message: "审核成功",
              });
              // this.doCancle();
              if (this.name == "first") {
                this.getData(-1, "/run/project/waitAuditListPage", this.name);
              } else if (this.name == "second") {
                this.getData(-1, "/run/project/auditPassListPage", this.name);
              } else if (this.name == "three") {
                this.getData(-1, "/run/project/auditRejectListPage", this.name);
              }
            }
          })
          .catch((err) => {
            return;
          });
      }
    },
    // doCancle() {
    //   this.dialoglevelVisible = false;
    //   this.level = "";
    // },
    sure() {
      if (!this.auditReason) {
        this.$message({
          message: "请输入驳回理由",
          type: "warning",
        });
        return false;
      }
      const parmar = {
        resourceId: this.resourceId,
        auditReason: this.auditReason,
        auditState: "40",
        currentAuditState: this.currentAuditState,
      };
      this.$post("/run/project/modifyAuditRejectState", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: "成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.auditReason = "";
            console.log(this.name);
            if (this.name == "first") {
              this.getData(-1, "/run/project/waitAuditListPage", this.name);
            } else if (this.name == "second") {
              this.getData(-1, "/run/project/auditPassListPage", this.name);
            } else if (this.name == "three") {
              this.getData(-1, "/run/project/auditRejectListPage", this.name);
            }
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.auditReason = "";
      this.dialogVisible = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
  },
  mounted: function () { },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;

  .el-textarea__inner {
    height: 200px;
  }
}

.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;

      .el-tab-pane {
        height: 100%;
      }
    }
  }
}

.btnBox {

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
